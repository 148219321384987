<template>
  <div class="app-wrapper">
    <Nav />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Nav from './Nav';
export default {
  components: {
    Nav,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    $route: {
      handler: 'init',
      immediate: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    init() {
      if (this.$route.name === 'app') {
        if (this.loaded) {
          this.$router.push('/');
        } else {
          this.loaded = true;
          if (this.currentUser) {
            if (this.currentUser.role === 'dentist') {
              this.$router.push('/app/users');
            } else {
              this.$router.push('/app/examinations');
            }
          } else {
            this.$router.push('/app/examinations');
          }
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.app-wrapper {
  padding: 10px 10px 10px $nav-width + 10px;
}

@media screen and (max-width: $xsm) {
  .app-wrapper {
    padding: 10px 10px $sm-nav-height + 10px;
  }
}
@media print {
  .app-wrapper {
    padding: 0;
  }
}
</style>