<template>
  <div class="nav">
    <div class="nav__logo">
      <router-link to="/">
        <img src="@/assets/img/logo-sm.svg" alt="3VRO" />
      </router-link>
    </div>
    <div class="nav__menu" v-if="isAuthenticated">
      <div
        class="nav__slider"
        :class="{ 
          'nav__slider--2' : $route.name === 'app-examinations' && currentUser.role !== 'pacient',
          'nav__slider--3' : $route.name === 'app-roentgenography',
          'nav__slider--4' : $route.name === 'app-inquiries' }"
      ></div>
      <router-link
        to="/app/users"
        class="nav__item"
        active-class="nav__item--active"
        v-if="currentUser.role !== 'pacient'"
      >
        <div class="icon">C</div>
        <span v-if="currentUser.role === 'dentist'">ПАЦИЕНТИ</span>
        <span v-else>ПОТРЕБИТЕЛИ</span>
      </router-link>
      <router-link to="/app/examinations" class="nav__item" active-class="nav__item--active">
        <div class="icon">A</div>
        <span>НАПРАВЛЕНИЯ</span>
      </router-link>
      <router-link
        to="/app/roentgenography"
        class="nav__item"
        active-class="nav__item--active"
        v-if="currentUser.role === 'admin' || currentUser.role === 'employee'"
      >
        <div class="icon">B</div>
        <span>РЕНТГЕНОГРАФИИ</span>
      </router-link>
      <!-- <router-link
        to="/app/inquiries"
        class="nav__item"
        exact-active-class="nav__item--active"
        v-if="currentUser.role === 'admin' || currentUser.role === 'employee'"
      >
        <div class="icon">D</div>
        <span>ЗАПИТВАНИЯ</span>
      </router-link>-->
    </div>
    <div class="nav__menu" v-else>
      <div class="nav__slider"></div>
      <router-link to="/app/noreg-examination" class="nav__item" active-class="nav__item--active">
        <div class="icon">A</div>
        <span>НАПРАВЛЕНИЯ</span>
      </router-link>
    </div>
    <div
      class="nav__footer"
      title="Достъп до старата система на 3V Rentgen - Ако искате да прегледате стари снимки и резултати"
    >
      <div class="old">
        <a href="http://78.83.69.207/authsys_pg_login.php" target="_blank">
          <p class="software-download-toggle">
            <span class="icon">E</span>
            <span>СТАРА СИСТЕМА</span>
          </p>
        </a>
      </div>
      <div class="software-download-wrapper">
        <p class="software-download-toggle" @click="showSoftware = !showSoftware">
          <span class="icon">8</span>
          <span
            title="Изберете и изтеглете различен софтуер за да прегледате снимките на компютър"
          >СОФТУЕР ЗА ПРЕГЛЕД</span>
        </p>
        <a
          href="https://www.dropbox.com/s/j062zhprfghqwwc/KDIS_3D_CBCT_vers2.0.zip?dl=0"
          target="_blank"
          class="software-download-item"
          :class="{ 'software-download-item--active': showSoftware }"
        >
          <span class="icon">8</span>
          KDIS 3D CBCT v. 2.0
        </a>
        <a
          href="https://www.dropbox.com/s/qlzsgtdofdcdhdj/KDIS_3D_CBCT_vers3.3.9.zip?dl=0"
          target="_blank"
          class="software-download-item"
          :class="{ 'software-download-item--active': showSoftware }"
        >
          <span class="icon">8</span>
          KDIS 3D CBCT v. 3.3.9
        </a>
        <a
          href="https://www.dropbox.com/s/ligd971mbju2gj3/Planmeca_Romexis_Viewer_383R.zip?dl=0"
          target="_blank"
          class="software-download-item"
          :class="{ 'software-download-item--active': showSoftware }"
        >
          <span class="icon">8</span>
          Romexis Viewer v. 3
        </a>
        <a
          href="https://www.dropbox.com/s/mvc1box2x5cq1kj/Planmeca_Romexis_Viewer_451R.zip?dl=0"
          target="_blank"
          class="software-download-item"
          :class="{ 'software-download-item--active': showSoftware }"
        >
          <span class="icon">8</span>
          Romexis Viewer v. 4
        </a>
        <a
          href="https://www.dropbox.com/s/zyof38n14ak02c6/Planmeca_Romexis_Viewer_520R.zip?dl=0"
          target="_blank"
          class="software-download-item"
          :class="{ 'software-download-item--active': showSoftware }"
        >
          <span class="icon">8</span>
          Romexis Viewer v. 5
        </a>
        <a
          href="https://www.dropbox.com/s/4bs0qfdl9prtac5/Planmeca_Romexis_Viewer_600R.zip?dl=0"
          target="_blank"
          class="software-download-item"
          :class="{ 'software-download-item--active': showSoftware }"
        >
          <span class="icon">8</span>
          Romexis Viewer v. 6
        </a>
      </div>
      <div
        class="side-help"
        :class="{ 'side-help--hidden': showSoftware, 'side-help--clickable': currentUser.role === 'dentist' }"
        v-if="currentUser.role !== 'pacient'"
      >
        <img src="../../assets/img/help/sideHelp1.svg" alt="Как да издам направление" />
        <img
          src="../../assets/img/help/sideHelp3.svg"
          alt="Стъпки за издаване на направление"
          v-if="currentUser.role === 'dentist'"
          @click="getHelpFile()"
        />
        <img
          src="../../assets/img/help/sideHelp2.svg"
          alt="Стъпки за издаване на направление"
          v-else
        />
      </div>
      <div class="user-wrapper" v-if="isAuthenticated" @click="editUser = currentUser._id">
        <div class="avatar">
          <img
            v-if="currentUser.avatar"
            :src="`${uploads}/avatars/${currentUser.avatar}`"
            alt="avatar"
          />
          <span class="icon" v-else>c</span>
        </div>
        <div class="user">
          <p>{{ currentUser.name }}</p>
          <span>
            <span class="nonvisible">nonvisible text</span>
            <span class="hovered">Редакция и изход от профила</span>
            <span class="nonhovered">{{ roles[currentUser.role] }}</span>
          </span>
        </div>
      </div>
    </div>
    <UserPanel
      v-if="editUser"
      @close-panel="editUser = ''"
      :id="editUser"
      :tabsSpace="$route.name === 'app-users'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserPanel from "@/views/panels/UserPanel";
export default {
  components: {
    UserPanel
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated"]),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    }
  },
  watch: {
    "$route.name"() {
      this.showSoftware = false;
    }
  },
  data() {
    return {
      hovered: false,
      editUser: "",
      roles: {
        admin: "Администратор",
        employee: "МДЛ Служител",
        dentist: "Дентален лекар",
        pacient: "Пациент"
      },
      showSoftware: false
    };
  },
  methods: {
    hover() {
      if (!this.hovered) {
        this.hovered = true;
      }
    },
    unhover() {
      if (this.hovered) {
        this.hovered = false;
      }
    },
    getHelpFile() {
      if (this.currentUser.role === "dentist") {
        window.open(`${process.env.VUE_APP_FILES_URI}/help_dentist.pdf`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.nav {
  @include gradient();
  color: #fff;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: $nav-width;
  z-index: 5;
}

.nav__logo {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  margin-bottom: 10px;
  padding: 10px;
  @include hover();
  a {
    display: inline-block;
    height: 100%;
    color: #fff;
  }
  img {
    height: 100%;
    width: auto;
    display: inline-block;
  }
}

.nav__menu {
  padding-left: 20px;
  position: relative;
}
.nav__slider {
  background-color: $background;
  content: "";
  height: 50px;
  left: 0;
  position: absolute;
  width: 100%;
  @include transition(all $transition $cubic);
  &.nav__slider--2 {
    @include transform(translateY(100%));
  }
  &.nav__slider--3 {
    @include transform(translateY(200%));
  }
  &.nav__slider--4 {
    @include transform(translateY(300%));
  }
}

.nav__item {
  align-items: center;
  color: $background;
  display: flex;
  height: 50px;
  position: relative;
  @include transition(all $transition $cubic);
  @include noselect();
  z-index: 2;
  > span {
    font-weight: 700;
  }
  .icon {
    margin-right: 10px;
    font-size: 2rem;
  }
}

.nav__item--active {
  color: #45688e;
}

.nav__footer {
  align-items: center;
  bottom: 0;
  flex-direction: column;
  display: flex;
  padding: 15px 10px;
  position: absolute;
  width: 100%;
  .user-wrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 10px;
    position: relative;
    width: 100%;
    @include transition(all $transition $cubic);
    &::before {
      background-color: #00c2a9;
      border-radius: $input-height + 10px;
      content: "";
      height: $input-height + 10px;
      left: 5px;
      position: absolute;
      top: 5px;
      width: $input-height + 10px;
      @include transition(all $transition $cubic);
    }
  }
  .avatar {
    align-items: center;
    background-color: #fff;
    border: 4px solid #fff;
    border-radius: 50%;
    display: flex;
    height: $input-height;
    justify-content: center;
    margin-right: 10px;
    min-width: $input-height;
    overflow: hidden;
    position: relative;
    width: $input-height;
    z-index: 1;
    @include noselect();
    &::before {
      align-items: center;
      background-color: #00c2a9;
      border-radius: 50%;
      color: #fff;
      content: "J";
      display: flex;
      font-family: "icons";
      font-size: 1.5rem;
      height: 100%;
      justify-content: center;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      @include transition(all $transition $cubic);
      @include transform(scale(0));
    }
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    .icon {
      @include gradient();
      align-items: center;
      color: #fff;
      display: flex;
      font-size: 2rem;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }
  .user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 1;
    p {
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    span {
      color: rgba($color: #fff, $alpha: 0.8);
      display: block;
      font-size: 0.7rem;
      position: relative;
      .nonvisible {
        opacity: 0;
      }
      .hovered,
      .nonhovered {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      .hovered {
        opacity: 0;
        @include transform(translateX(100%));
        @include transition(all $transition $cubic);
      }
      .nonhovered {
        @include transition(all $transition $cubic);
      }
    }
  }
  .user-wrapper:hover {
    &::before {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      width: calc(100% + 10px);
    }
    .avatar::before {
      opacity: 0.9;
      @include transform(scale(1));
    }
    .user {
      span {
        .hovered {
          @include transform(translateX(0));
          opacity: 1;
        }
        .nonhovered {
          @include transform(translateX(100%));
          opacity: 0;
        }
      }
    }
  }
}

.old {
  background-color: rgba($color: rgb(250, 100, 0), $alpha: 0.9);
  border-radius: 5px;
  margin-bottom: 10px;
  width: 90%;
  p {
    color: white;
  }
}

.side-help {
  height: 198px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  width: 198px;
  @include transition(all $transition $cubic);
  img {
    width: 100%;
    @include transition(all $transition $cubic);
    &:last-child {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
    }
  }

  &:hover {
    img {
      &:first-child {
        opacity: 0;
      }
      &:last-child {
        opacity: 1;
      }
    }
  }

  &.side-help--hidden {
    height: 0;
    margin: 0;
  }

  &.side-help--clickable {
    cursor: pointer;
  }
}

.software-download-wrapper {
  background-color: rgba($color: #fff, $alpha: 0.3);
  border-radius: 5px;
  margin-bottom: 10px;
  width: 90%;
}

.software-download-toggle {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  height: 40px;
  justify-content: space-around;
  @include transition(all $transition $cubic);
  width: 100%;
  .icon {
    font-size: 1.6rem;
  }
  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.2);
  }
}

.software-download-item {
  border-bottom: 0px solid transparent;
  color: #fff;
  display: block;
  font-size: 0.9rem;
  height: 0;
  margin: 0 20px;
  overflow: hidden;
  width: calc(100% - 40px);
  @include transition(all $transition $cubic);
  .icon {
    color: rgba($color: #fff, $alpha: 0.5);
    margin-right: 5px;
  }
  &.software-download-item--active {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.5);
    cursor: pointer;
    height: 20px;
    margin: 10px 20px;
    &:hover {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: $xl) {
  .nav__footer {
    .user-wrapper {
      &::before {
        border-radius: $xl-input-height + 10px;
        height: $xl-input-height + 10px;
        width: $xl-input-height + 10px;
      }
    }
    .avatar {
      height: $xl-input-height;
      min-width: $xl-input-height;
      width: $xl-input-height;
    }
  }
}

@media screen and (max-width: $l) {
  .nav__logo {
    margin-bottom: 10px;
  }
  .nav__slider,
  .nav__item {
    height: 60px;
  }
}

@media screen and (max-width: $xsm) {
  .nav__logo,
  .side-help,
  .software-download-wrapper,
  .old {
    display: none;
  }
  .nav {
    bottom: 0;
    display: flex;
    height: $sm-nav-height;
    justify-content: space-between;
    padding-left: 6px;
    top: initial;
    width: 100%;
  }
  .nav__menu {
    align-items: center;
    display: flex;
    padding: 0;
  }
  .nav__item {
    align-items: center;
    height: 70px;
    flex-direction: column;
    justify-content: space-around;
    width: 80px;
    font-size: 8px;
    &:not(:last-child) {
      margin-right: 8px;
    }
    .icon {
      margin: 0;
      font-size: 28px;
    }
  }
  .nav__slider {
    background-color: rgba($color: #fff, $alpha: 0.8);
    border-radius: 10px;
    height: 70px;
    left: 0;
    width: 80px;
    &.nav__slider--2 {
      @include transform(translateX(calc(100% + 8px)));
    }
    &.nav__slider--3 {
      @include transform(translateX(calc(200% + 16px)));
    }
    &.nav__slider--4 {
      @include transform(translateX(calc(300% + 24px)));
    }
  }
  .nav__footer {
    display: inline-flex;
    padding: 10px;
    position: relative;
    width: initial;

    .user-wrapper {
      display: inline-flex;
      width: initial;
    }
    .avatar {
      margin: 0;
    }
    .user {
      display: none;
    }
  }
}

@media screen and (max-height: 685px) {
  .side-help {
    display: none;
  }
}

@media print {
  .nav {
    display: none;
  }
}
</style>